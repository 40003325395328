<template>
  <div class="detail-index-container">
    <div class="content">
      <!-- 顶部大图 -->
      <div class="content-banner">
        <img src="../../../assets/img/propaganda/banner.png" alt="" />
        <div class="wrapper-text">
          <div class="title">浙江省智能家电产业大脑 指定官方选品平台</div>

          <ul class="text-list">
            <li class="text-list-item">
              <span class="text-list-item-circle"></span
              >汇聚1000+家电生产企业，多维度、多场景实时监测，全链条可视化跟踪
            </li>
            <li class="text-list-item">
              <span class="text-list-item-circle"></span>
              赋能贸易企业，提供选品全生命周期数字化服务
            </li>
          </ul>

          <a-button type="primary" class="detail-btn" @click="handleDetail"
            >了解详情 <a-icon type="right-circle"
          /></a-button>
        </div>
      </div>

      <!-- 中间小卡片 -->
      <div class="content-card">
        <div class="content-card-item">
          <div>
            <img src="../../../assets/img/propaganda/组 32493.png" alt="" />
            <div class="text">“数智化”实现供应链可信制造</div>
          </div>
        </div>
        <div class="content-card-item">
          <div>
            <img src="../../../assets/img/propaganda/b2m.png" alt="" />
            <div class="text">双向赋能构建B2M快车道</div>
          </div>
        </div>
        <div class="content-card-item">
          <div>
            <img src="../../../assets/img/propaganda/组 32492.png" alt="" />
            <div class="text">实现新奇特家电精准交付</div>
          </div>
        </div>
      </div>

      <!-- 大title -->
      <div class="content-title">
        <div class="content-title-item">特色服务</div>
      </div>

      <!-- tabs页 -->
      <div class="content-tabs">
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="新奇特精选">
            <Card :config="data[0]" />
          </a-tab-pane>
          <a-tab-pane key="2" tab="可信制造动态评价" force-render>
            <Card :config="data[1]" />
          </a-tab-pane>
          <a-tab-pane key="3" tab="订单监测大脑">
            <Card :config="data[2]" />
          </a-tab-pane>
          <a-tab-pane key="4" tab="产品定制">
            <Card :config="data[3]" />
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../../index/components/DetailIndex/Card.vue";
export default {
  data() {
    return {
      data: [
        {
          imgUrl: require("../../../assets/img/propaganda/新奇特.png"),
          circleTitle: "提高采购效率 缩短选品周期",
          typeList: [
            "135大类新品、爆品、优品",
            "材质、功能、质量、安全等多维度测试",
            "千家工厂出厂价供应",
            "18小时内快速响应",
          ],
          numberList: [
            {
              value: "135大类",
              title: "新品、爆品、优品",
            },
            {
              value: "18小时",
              title: "快速响应",
            },
            {
              value: "1000+",
              title: "出厂价供应",
            },
          ],
        },

        {
          imgUrl: require("../../../assets/img/propaganda/可信制造动态评价.png"),
          circleTitle: "降低验厂成本 缩短选厂周期",
          typeList: [
            "近60项数据全面、客观、真实展示工厂",
            "基础资质、智能程度、生产控制、综合良率等多维度智能画像",
            "全方位动态评价体系，白名单准入机制",
          ],
          numberList: [
            {
              value: "60项数据",
              title: "综合评估工厂",
            },
            {
              value: "5大维度",
              title: "展示工厂能力",
            },
          ],
        },

        {
          imgUrl: require("../../../assets/img/propaganda/订单监测.png"),
          circleTitle: "降低跟单成本 提高运营效率",
          typeList: [
            "基于人工智能的采购订单生产全节点可视化",
            "生产进度、质检数据等直观准确显示",
            "每日更新，系统预警实时介入协调",
          ],
          numberList: [
            {
              value: "可视化",
              title: "订单生产全节点",
            },
            {
              value: "每日监测",
              title: "及时高效反馈",
            },
            {
              value: "及时预警",
              title: "实时介入，异常报警",
            },
          ],
        },

        {
          imgUrl: require("../../../assets/img/propaganda/产品定制.png"),
          circleTitle: "降低定制成本 缩短定制周期",
          typeList: [
            "中科院技术，自主芯片，差异化功能定制",
            "欧美本土创新设计，上百种产品解决方案",
            "让产品独具一格，让生意变得简单！",
          ],
          numberList: [
            {
              value: "技术性",
              title: "自主研发智能芯片",
            },
            {
              value: "创新性",
              title: "上百种产品解决方案",
            },
            {
              value: "独特性",
              title: "差异化定制",
            },
          ],
        },
      ],
    };
  },
  components: {
    Card,
  },

  methods: {
    handleDetail() {
      this.$router.push("/");
    },
  },

  created() {},
};
</script>
<style lang="scss" scoped>
.detail-index-container {
  width: 85%;
  background: #fff;
  margin: 0 auto;
}
.content {
  width: 100%;
  height: 100%;
  position: relative;
  // background: #fff;

  &-banner {
    width: 85%;
    margin: 0 auto;
    overflow: hidden;
    img {
      width: 100%;
      height: 468px;
    }

    @media all and (min-width: 1280px) {
      .wrapper-text {
        left: 300px;
      }
    }

    .wrapper-text {
      position: absolute;
      text-align: left;
      left: 200px;
      top: 80px;

      .title {
        width: 400px;
        font-size: 35px;
        font-weight: bold;
        color: #323233;
        line-height: 60px;
      }
    }
  }

  &-card {
    width: 80%;
    margin: -20px auto;
    display: flex;
    justify-content: space-around;
    padding-bottom: 5rem;
    &-item {
      width: 32%;
      height: 182px;

      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background: #fff;

      img {
        width: 80px;
        height: 80px;
        object-fit: cover;
      }

      .text {
        margin-top: 15px;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  &-title {
    justify-content: center;
    padding-bottom: 3rem;
    &-item {
      font-size: 44px;
      font-weight: bold;
      color: #333333;
    }
  }

  &-tabs {
    margin: 0 auto;
  }
}

.text-list-item-circle {
  margin-right: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fe8432;
}

.text-list-item {
  font-size: 20px;
  color: #323233;
  font-weight: 400;
  margin-top: 10px;
}

.detail-btn {
  width: 166px;
  height: 52px;
  background: linear-gradient(132deg, #fe8432 0%, #f94639 100%);
  border-radius: 26px 26px 26px 26px;

  font-size: 20px;
  line-height: 52px;
}

/deep/ .ant-tabs-nav .ant-tabs-tab {
  margin: 0 45px 0 45px;
  font-size: 18px;
}

.ant-divider-horizontal {
  background: #ccc;
}
</style>
