<template>
  <div class="card">
    <div class="wrapper-card">
      <div class="wrapper-card-left">
        <img :src="config.imgUrl" alt="" />
        <div class="wrapper-circle">
          <div class="wrapper-circle-inner">
            <div class="wrapper-circle-inner-text">
              {{ config.circleTitle }}
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-card-right">
        <ul class="text-list">
          <li
            class="text-list-item"
            v-for="(item, i) in config.typeList"
            :key="i"
          >
            <span class="text-list-item-circle"></span>{{ item }}
          </li>
        </ul>

        <a-divider />

        <div class="detail-num">
          <div
            class="detail-num-item"
            v-for="(item, i) in config.numberList"
            :key="i"
          >
            <div class="num">{{ item.value }}</div>
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    console.log(this.config);
  },
};
</script>

<style lang="scss" scoped>
.card {
  .wrapper-card {
    width: 1180px;
    height: 450px;
    margin: 0 auto;
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 2rem;

    .wrapper-card-left {
      width: 35%;
      height: 100%;
      position: relative;
      .wrapper-circle {
        width: 210px;
        height: 210px;
        border-radius: 50%;
        border: 1px solid #fe8432;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 14px;

        &-inner {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          background: #fe8432;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          &-text {
            width: 140px;
            font-size: 20px;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px 0 0 10px;
      }
    }
    .wrapper-card-right {
      width: 65%;
      height: 100%;
      // border: 1px solid #123456;
      box-sizing: border-box;
      padding: 50px;
      text-align: left;

      .detail-num {
        display: flex;
        justify-content: space-around;

        .detail-num-item {
          // border: 1px solid #000;
          width: 30%;
          margin-right: 13px;

          .num {
            font-size: 40px;
            text-align: center;
            font-weight: 500;
            color: #ff4600;
          }
          .title {
            font-size: 16px;
            text-align: center;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }
  }
}

.text-list {
  height: 11rem;
}
.text-list-item-circle {
  margin-right: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fe8432;
}

.text-list-item {
  font-size: 20px;
  color: #323233;
  font-weight: 400;
  margin-top: 10px;
}
</style>
